import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

Bugsnag.start({
  apiKey: 'e40af045a0b6657744d4acb280a70b1b',
  releaseStage: 'production',
  enabledReleaseStages: ['production', 'staging'],
  plugins: [new BugsnagPluginVue(Vue)]
})
Bugsnag.getPlugin('vue').installVueErrorHandler(Vue)
