export default function ({ store, route, error }) {
  const categoryPattern = /^category-page/
  const foresightPattern = /^foresightPage/
  if (categoryPattern.test(route.name) || foresightPattern.test(route.name)) {
    store.dispatch('page/setPageContentForRoutePath', route.path)
    if (foresightPattern.test(route.name)) {
      store.dispatch('page/loadForesightPageContent')
    }
    if (store.getters['page/pageContent'] === undefined) {
      return error({ statusCode: 404, message: 'not found' })
    }
  }
}
