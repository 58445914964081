
import { htmlize } from '../../utils/StringUtils'

export default {
  name: 'NewsBlock',
  props: {
    news: {
      required: true,
      type: Object
    },
    isLast: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    newsTitle () {
      return htmlize(this.news.title)
    },
    newsLink () {
      let url = ''
      if (this.news.path !== null && this.news.path !== '') {
        url = 'https://www.enerdata.net' + this.news.path
      }
      return url
    },
    newsText () {
      return htmlize(this.news.news_text.split('</p>')[0])
    }
  }
}
