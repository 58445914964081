
export default {
  name: 'FooterColumn',
  props: {
    content: {
      required: true,
      type: Object
    }
  }
}
