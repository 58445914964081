import MarkdownIt from 'markdown-it'
import { renderText } from '@/utils/StringUtils'
const markdown = new MarkdownIt()

export const state = () => ({
  message: null
})

export const getters = {
  message: state => state.message,
  keyEstimateBlocks: (state, getters, rootState, rootGetters, context) => {
    const returnedArray = []
    const pages = rootGetters.pages
    const n = pages.length
    for (let i = 0; i < n; i++) {
      const page = pages[i]
      if (page.keys !== undefined) {
        const keyItem = page.keys.estimates
        if (keyItem.displayedOnHomepage === true) {
          const title = keyItem.title !== '' ? keyItem.title : page.category1
          const keyFigure = {
            id: page.id,
            url: '/' + page.urlCategorie + page.url + '.html',
            title: renderText(title),
            icon: page.icone,
            value: keyItem.number,
            text: renderText(markdown.render(keyItem.text))
          }
          returnedArray.push(keyFigure)
        }
      }
    }
    return returnedArray
  },
  keyDataBlocks: (state, getters, rootState, rootGetters, context) => {
    const returnedArray = []
    const pages = rootGetters.pages
    const n = pages.length
    for (let i = 0; i < n; i++) {
      const page = pages[i]
      if (page.keys !== undefined) {
        const keyItem = page.keys.data
        if (keyItem.displayedOnHomepage === true) {
          const title = keyItem.title !== '' ? keyItem.title : page.category1
          const keyFigure = {
            id: page.id,
            url: '/' + page.urlCategorie + page.url + '.html',
            title: renderText(title),
            icon: page.icone,
            value: keyItem.number,
            text: renderText(markdown.render(keyItem.text))
          }
          returnedArray.push(keyFigure)
        }
      }
    }
    return returnedArray
  },
  keyDecarbonizationBlocks: (state, getters, rootState, rootGetters, context) => {
    const returnedArray = []
    const pages = rootGetters.pages
    const n = pages.length
    for (let i = 0; i < n; i++) {
      const page = pages[i]
      if (page.keys !== undefined) {
        const keyItem = page.keys.decarbonisation
        if (keyItem.displayedOnHomepage === true) {
          const title = keyItem.title !== '' ? keyItem.title : page.category1
          const keyFigure = {
            id: page.id,
            url: '/' + page.urlCategorie + page.url + '.html',
            title: renderText(title),
            icon: page.icone,
            value: keyItem.numberEstimated,
            secondValue: keyItem.numberHistorical,
            text: renderText(markdown.render(keyItem.text))
          }
          returnedArray.push(keyFigure)
        }
      }
    }
    return returnedArray
  }
}

export const actions = {
  setMessage ({ commit }, value) {
    commit('SET_MESSAGE', value)
  },
  clearMessage ({ commit }) {
    commit('SET_MESSAGE', null)
  }
}

export const mutations = {
  SET_MESSAGE (state, value) {
    state.message = value
  }
}
