function getEnergyCode (code) {
  switch (code.substr(0, 3)) {
    case 'hyl' : return 'oil'
    case 'pet' : return 'oil'
    case 'cms' : return 'coal'
    case 'gna' : return 'gas'
    case 'elc' : return 'electricity'
    case 'ppe' : return 'oil_products'
    case 'enc' : return 'biomass'
    case 'vge' : return 'geothermal_heat'
    case 'vdv' : return 'solar_heat'
    case 'vap' : return 'heat'
    case 'co2' :
      switch (code.substr(-3)) {
        case 'pet' : return 'oil'
        case 'cms' : return 'coal'
        case 'gaz' : return 'gas'
      }
      break
    default :
      switch (code) {
        case 'pcrenhydelepd' : return 'renewables'
        case 'pcnenrelepd' : return 'non_renewables'
        case 'pcothelepd' : return 'others'
        case 'pceeoelepd' : return 'wind'
        case 'pcedvelepd' : return 'solar'
      }
      break
  }
  return null
}

function getShortTitleForEnergyCode (energyCode, i18n) {
  if (energyCode === null) {
    return null
  }
  return i18n.t('shortTitle.' + energyCode)
}

function getNumberFormatStringForNbDecimal (nbDecimal) {
  let format = '#.'
  if (nbDecimal > 0) {
    for (let i = 0; i < nbDecimal; i++) {
      format += '#'
    }
  }
  return format
}

export { getEnergyCode, getShortTitleForEnergyCode, getNumberFormatStringForNbDecimal }
