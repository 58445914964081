function capitalize (s) {
  if (typeof s !== 'string') { return '' }
  return s.charAt(0).toUpperCase() + s.slice(1)
}

function htmlize (s) {
  const regex = /co2/gi
  s = s.replace(regex, 'CO<sub>2</sub>')
  return s
}

function checkLatinString (s) {
  const regex = /[\u3130-\u318F\uAC00-\uD7AF\u4E00-\u9FBF\u3040-\u309F\u30A0-\u30FF\p{Script=Cyrillic}]/ugi
  return (s.length > 0) && s.match(regex) === null
}

function renderText (s) {
  return htmlize(s).replace('href="', 'target ="_blank" href="').replace(/<strong\b[^>]*>(.*?)<\/strong>/g, '<span class="keyText">$1</span>')
}

export { capitalize, htmlize, checkLatinString, renderText }
