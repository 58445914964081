
export default {
  name: 'EnerMobileMenu',
  props: {
    items: {
      type: Array,
      required: true
    },
    currentPage: {
      type: Object,
      default: null
    },
    isLoggedIn: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    getCollapseIdName (id) {
      return 'collapse-' + id
    }
  }
}
