const regionColor = {
  moy: '#a13e0e',
  afriq: '#ec6625',
  ausasi: '#f18c5b',
  asie: '#fad8c7',
  aml: '#bdf3ff',
  amnord: '#5be1ff',
  cei: '#008aa9',
  europe: '#004a5b'
}

const energyColor = {

  coal: '#b47836',
  gas: '#808080',
  oil: '#000000',
  biomass: '#6fc22f',
  solar: '#c7af2e',
  wind: '#4AAEC7',
  geothermal_heat: '#887400',
  electricity: '#ffd360',
  oil_products: '#333333',
  solar_heat: '#c7af2e',
  heat: '#ec6625',
  renewables: '#174211',
  non_renewables: '#8d7979',
  others: '#8d7979',
  hydro: '#2B2D7F',
  nuclear: '#EC6625'
}

const categoryColor = {

  total_energy: '#ec6625',
  crude_oil: energyColor.oil,
  oil_products: energyColor.oil_products,
  natural_gas: energyColor.gas,
  coal_lignite: energyColor.coal,
  power: energyColor.electricity,
  renewables: energyColor.renewables,
  emissions: '#004a5b'
}

const foresightColor = {
  EnerGreen: '#008F62',
  'Buildings - sufficiency': '#00A2D2',
  'Buildings - efficiency': '#F39325',
  'Buildings - heat pumps': '#FFDE12',
  'Buildings - others': '#758F00',
  Industry: '#008F62',
  Others: '#9b3605',
  income: '#bf0001',
  outcome: '#00758e',
  result: '#008e62'
}

function getColorForRegionCode (code) {
  if (code === null || code === undefined) {
    return null
  }
  // on force les minuscules pour éviter les erreurs
  code = code.replace(/\s+/g, '_').toLowerCase()
  if (regionColor[code] !== undefined) {
    return regionColor[code]
  } else {
    return '#ffffff'
  }
}

function getColorForEnergyName (energy) {
  if (energy === null || energy === undefined) {
    return null
  }
  // on force les minuscules pour éviter les erreurs
  energy = energy.replace(/\s+/g, '_').toLowerCase()
  if (energyColor[energy] !== undefined) {
    return energyColor[energy]
  } else {
    return '#ffffff'
  }
}

function getColorForCategoryName (category) {
  if (category === null || category === undefined) {
    return null
  }
  // on force les minuscules pour éviter les erreurs
  category = category.replace(/\s+/g, '_').toLowerCase()
  if (categoryColor[category] !== undefined) {
    return categoryColor[category]
  } else {
    return '#ffffff'
  }
}

function getColorForForesightName (foresightCategory) {
  if (foresightCategory === null || foresightCategory === undefined) {
    return null
  }
  if (foresightColor[foresightCategory] !== undefined) {
    return foresightColor[foresightCategory]
  } else {
    return '#ffffff'
  }
}

export { getColorForRegionCode, getColorForEnergyName, getColorForCategoryName, getColorForForesightName }
