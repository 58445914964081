
export default {
  layout: 'base',
  props: {
    error: {
      required: true,
      type: Object
    }
  },
  computed: {
    pageLandscapeImage () {
      return require('@/assets/images/jumbotron/homepage.jpg')
    }
  }
}
