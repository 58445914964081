export const state = () => ({
  pageContent: null,
  pageData: [],
  currentYear: null,
  mapCountry: null,
  benchmarkCountries: [],
  foresightContent: null
})

export const getters = {
  pageContent: (state) => {
    return state.pageContent
  },
  nbDecimal: (state) => {
    return state.pageContent.map.nbDecimale
  },
  rankingOrder: (state) => {
    return state.pageContent.rankingOrder
  },
  mapContent: (state) => {
    return state.pageContent.map
  },
  mapCountry: (state, getters, rootState, rootGetters) => {
    if (state.mapCountry === null) {
      return rootGetters.world
    } else {
      return state.mapCountry
    }
  },
  benchmarkCountries: (state) => {
    return state.benchmarkCountries
  },
  foresightContent: (state) => {
    return state.foresightContent
  }
}

export const actions = {
  setPageContentForRoutePath ({ commit, rootGetters }, path) {
    const data = rootGetters.pageByRoutePath(path)
    commit('SET_PAGE_CONTENT', data)
  },
  setMapCountry ({ commit }, data) {
    commit('SET_MAP_COUNTRY', data)
  },
  clearMapCountry ({ commit }) {
    commit('SET_MAP_COUNTRY', null)
  },
  clearPageContent ({ commit }) {
    commit('SET_PAGE_CONTENT', null)
  },
  setBenchmarkCountries ({ commit }, data) {
    commit('SET_BENCHMARK_COUNTRIES', data)
  },
  removeBenchmarkCountry ({ state, commit }, country) {
    const newBenchmarkCountries = state.benchmarkCountries.filter(function (benchmarkCountry) { return benchmarkCountry !== country })
    commit('SET_BENCHMARK_COUNTRIES', newBenchmarkCountries)
  },
  clearBenchmarkCountries ({ commit }) {
    commit('SET_BENCHMARK_COUNTRIES', [])
  },
  async loadForesightPageContent ({ commit }) {
    const foresightContent = await require('~/data/cop28-' + this.app.i18n.locale + '.json')
    commit('SET_FORESIGHT_CONTENT', foresightContent)
  }
}

export const mutations = {
  SET_PAGE_CONTENT (state, pageContent) {
    state.pageContent = pageContent
  },
  SET_MAP_COUNTRY (state, country) {
    state.mapCountry = country
  },
  SET_BENCHMARK_COUNTRIES (state, countries) {
    state.benchmarkCountries = countries
  },
  SET_FORESIGHT_CONTENT (state, content) {
    state.foresightContent = content
  }
}
