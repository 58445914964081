
export default {
  name: 'PublicationBlock',
  props: {
    title: {
      required: true,
      type: String
    },
    titleUrl: {
      required: true,
      type: String
    },
    imageName: {
      required: true,
      type: String
    },
    contentUrl: {
      required: true,
      type: String
    },
    contentText: {
      required: true,
      type: String
    },
    buttonIcon: {
      required: true,
      type: String
    },
    buttonText: {
      required: true,
      type: String
    }
  },
  computed: {
    image () {
      return require(`../../assets/images/publication/${this.imageName}`)
    }
  }
}
