function clone (obj) {
  let copy = null
  try {
    copy = JSON.parse(JSON.stringify(obj))
  } catch (ex) {
    alert('Clone is not supported by this browser')
  }
  return copy
}

function extensionGuesser (mimeType) {
  switch (mimeType) {
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xlsx'
    case 'application/vnd.ms-excel.sheet.macroenabled.main+xml':
      return 'xlsm'
    case 'application/pdf':
      return 'pdf'
    default:
      return null
  }
}

function slugify (text) {
  return text.toUpperCase()
    .replace(/\s+/g, '_')
    .replace(/\s+/g, '_')
    .replace(/__+/g, '-')
    .replace(/^_+/, '')
    .replace(/_+$/, '')
}

function CO2GraphSubFormatter (text) {
  if (text !== undefined && text.replace !== undefined) {
    return text.replace('/<sub>2</sub>/g', '[line-height:0; vertical-align:baseline; position: relative; font-size: 75%; bottom: -.25em;]2[/]')
  }
  return undefined
}

function getPivotArray (dataArray, categoryField, nameField, valueField) {
  let i
  const result = {}
  const ret = []
  let newCols = []
  for (i = 0; i < dataArray.length; i++) {
    if (!result[dataArray[i][nameField]]) {
      result[dataArray[i][nameField]] = {}
    }
    result[dataArray[i][nameField]][dataArray[i][categoryField]] = dataArray[i][valueField]
    if (!newCols.includes(dataArray[i][categoryField])) {
      newCols.push(dataArray[i][categoryField])
    }
  }
  // newCols.sort()
  newCols = newCols.map(x => x.toString())
  let item = []
  item.push(categoryField)
  item.push.apply(item, newCols)
  ret.push(item)
  for (const key in result) {
    item = []
    item.push(key)
    for (i = 0; i < newCols.length; i++) {
      item.push(result[key][newCols[i]] || null)
    }
    ret.push(item)
  }
  return ret
}

// function apiToEnerBarChartDataReducer (result, data, categoryField, nameField, valueField) {
//   let item
//   if (Array.isArray(result)) {
//     item = result.find(item => item[categoryField] === data[categoryField])
//   } else {
//     result = []
//   }
//   if (item === undefined) {
//     item = {}
//     item[categoryField] = data[categoryField]
//     result.push(item)
//   }
//   item[data[nameField]] = data[valueField]
//   return result
// }
//
// function apiToEnerPieChartDataReducer (result, data, highlight) {
//   if (!Array.isArray(result)) {
//     result = []
//   }
//   const item = {}
//   item.category = data.serieShortTitle
//   item.value = data.value
//   item.color = getColorForEnergyName(data.energyCode)
//   item.pulled = false
//   if (highlight !== '') {
//     if (data.serieCode === highlight) {
//       item.pulled = true
//     }
//   }
//   result.push(item)
//   return result
// }

const debounce = (callback, wait) => {
  let timeoutId = null
  return (...args) => {
    window.clearTimeout(timeoutId)
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args)
    }, wait)
  }
}

function download (response, fileName) {
  const url = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }))
  const link = document.createElement('a')
  const contentDisposition = response.headers['content-disposition']
  if (contentDisposition) {
    let fileNameMatch = contentDisposition.match(/filename="(.+)"/)
    if (!fileNameMatch) {
      fileNameMatch = contentDisposition.match(/filename=(.+)/)
      if (fileNameMatch.length === 2) { fileName = fileNameMatch[1] }
    } else if (fileNameMatch.length === 2) { fileName = fileNameMatch[1] }
  }
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)

  link.click()
  link.remove()
  window.URL.revokeObjectURL(url)
}

export { download, clone, extensionGuesser, slugify, CO2GraphSubFormatter, getPivotArray, debounce }
