import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9867c9f0 = () => interopDefault(import('../pages/glossary.vue' /* webpackChunkName: "pages/glossary" */))
const _29c52a61 = () => interopDefault(import('../pages/image.vue' /* webpackChunkName: "pages/image" */))
const _5fba350d = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5b6a7444 = () => interopDefault(import('../pages/activate-account/_mail.vue' /* webpackChunkName: "pages/activate-account/_mail" */))
const _40e8f248 = () => interopDefault(import('../pages/change-password/_contactId/_token.vue' /* webpackChunkName: "pages/change-password/_contactId/_token" */))
const _180ce70a = () => interopDefault(import('../pages/_foresightPage.vue' /* webpackChunkName: "pages/_foresightPage" */))
const _f1a4277c = () => interopDefault(import('../pages/_category/_page.vue' /* webpackChunkName: "pages/_category/_page" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/glossary.html",
    component: _9867c9f0,
    name: "glossary___en"
  }, {
    path: "/glossary.html",
    component: _9867c9f0,
    name: "glossary___es"
  }, {
    path: "/glossary.html",
    component: _9867c9f0,
    name: "glossary___de"
  }, {
    path: "/glossary.html",
    component: _9867c9f0,
    name: "glossary___jp"
  }, {
    path: "/glossary.html",
    component: _9867c9f0,
    name: "glossary___kr"
  }, {
    path: "/image",
    component: _29c52a61,
    name: "image___en"
  }, {
    path: "/image",
    component: _29c52a61,
    name: "image___es"
  }, {
    path: "/image",
    component: _29c52a61,
    name: "image___de"
  }, {
    path: "/image",
    component: _29c52a61,
    name: "image___jp"
  }, {
    path: "/image",
    component: _29c52a61,
    name: "image___kr"
  }, {
    path: "/register.html",
    component: _5fba350d,
    name: "register___en"
  }, {
    path: "/register.html",
    component: _5fba350d,
    name: "register___es"
  }, {
    path: "/register.html",
    component: _5fba350d,
    name: "register___de"
  }, {
    path: "/register.html",
    component: _5fba350d,
    name: "register___jp"
  }, {
    path: "/register.html",
    component: _5fba350d,
    name: "register___kr"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___es"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___de"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___jp"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___kr"
  }, {
    path: "/activate-account/:mail?",
    component: _5b6a7444,
    name: "activate-account-mail___en"
  }, {
    path: "/activate-account/:mail?",
    component: _5b6a7444,
    name: "activate-account-mail___es"
  }, {
    path: "/activate-account/:mail?",
    component: _5b6a7444,
    name: "activate-account-mail___de"
  }, {
    path: "/activate-account/:mail?",
    component: _5b6a7444,
    name: "activate-account-mail___jp"
  }, {
    path: "/activate-account/:mail?",
    component: _5b6a7444,
    name: "activate-account-mail___kr"
  }, {
    path: "/change-password/:contactId?/:token?",
    component: _40e8f248,
    name: "change-password-contactId-token___en"
  }, {
    path: "/change-password/:contactId?/:token?",
    component: _40e8f248,
    name: "change-password-contactId-token___es"
  }, {
    path: "/change-password/:contactId?/:token?",
    component: _40e8f248,
    name: "change-password-contactId-token___de"
  }, {
    path: "/change-password/:contactId?/:token?",
    component: _40e8f248,
    name: "change-password-contactId-token___jp"
  }, {
    path: "/change-password/:contactId?/:token?",
    component: _40e8f248,
    name: "change-password-contactId-token___kr"
  }, {
    path: "/:foresightPage",
    component: _180ce70a,
    name: "foresightPage___en"
  }, {
    path: "/:foresightPage",
    component: _180ce70a,
    name: "foresightPage___es"
  }, {
    path: "/:foresightPage",
    component: _180ce70a,
    name: "foresightPage___de"
  }, {
    path: "/:foresightPage",
    component: _180ce70a,
    name: "foresightPage___jp"
  }, {
    path: "/:foresightPage",
    component: _180ce70a,
    name: "foresightPage___kr"
  }, {
    path: "/:category/:page?",
    component: _f1a4277c,
    name: "category-page___en"
  }, {
    path: "/:category/:page?",
    component: _f1a4277c,
    name: "category-page___es"
  }, {
    path: "/:category/:page?",
    component: _f1a4277c,
    name: "category-page___de"
  }, {
    path: "/:category/:page?",
    component: _f1a4277c,
    name: "category-page___jp"
  }, {
    path: "/:category/:page?",
    component: _f1a4277c,
    name: "category-page___kr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
