import axios from 'axios'

const $getTokenUrl = '/api/news/get_token/'

export default {
  async getLastNews ({
    displayId = 'get_energy_news_content',
    limit = 10,
    country = 'all',
    utility = 'all',
    topic = 'all',
    infrastructure = 'all'
  } = {}) {
    const response = await axios.get($getTokenUrl)
    const token = response.data
    const $url = `/api/news/${displayId}/${limit}/${country}/${utility}/${topic}/${infrastructure}/`
    return axios.get($url, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
  }
}
