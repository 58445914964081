export default function ({ $gtm, app, store, $i18n }) {
  app.router.afterEach((to) => {
    setTimeout(() => {
      const event = {
        event: 'datalayer_ready',
        language: app.i18n.locale,
        user_data: store.getters.gtmUserData
      }
      const pathParts = to.path.split('/')
      let contentGroupInd = 0
      for (const pathPart of pathParts) {
        if (pathPart !== '') {
          let contentGroupName = 'content_group'
          if (contentGroupInd > 0) {
            contentGroupName += '_' + contentGroupInd
          }
          event[contentGroupName] = pathPart.replace('.html', '')
          contentGroupInd++
        }
      }
      $gtm.push(event)
    }, 250)
  })
}
